import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Productivity App Template | Productivity App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/productivity-mobile-app/"
    metaDescription="Created by our in-house designer, this productivity app template embodies minimalistic design trends. Edit and customize online now with Uizard."
    description="
    h2:A customizable productivity mobile app design template
    <br/>
    There's a growing list of task trackers and productivity apps on the market. Using Uizard's productivity app template to create your own <a:https://uizard.io/prototyping/>prototype</a> will set you on the right path when it comes to getting your app to stand out from the crowd.
    <br/>
    h3:A task management app template with a minimalistic and scalable design
    <br/>
    Created by our in-house designers, our productivity app design template embodies minimalistic design trends. The scalable design makes it suitable for use for all types of businesses aiming to provide services within productivity and task management.
    <br/>
    h3:Personalize your clickable prototype with smart customization
    <br/>
    As with all our <a:https://uizard.io/templates/mobile-app-templates/>mobile app design templates</a>, the different sections of this productivity app design template (including the colors, fonts, texts, and images) are all adjustable. If you have a pre-defined design system in mind, you can import it into Uizard for automatic theme generation. Sign up to Uizard for free and supercharge your productivity today.
    "
    pages={[
      "A sign-in page",
      "A homepage, featuring daily progress and featured tasks, and a classic native mobile app sticky footer",
      "A task tracking page, including a list of tasks",
      "A profile page",
    ]}
    projectCode="qRmzZWvZJvc6GV3rwM5X"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Productivity Mobile App: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Productivity Mobile App: daily progress screen"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Productivity Mobile App: login screen"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Productivity Mobile App: menu"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of Productivity Mobile App template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/productivity-app/productivity-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/productivity-app/productivity-app-daily-progess.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/productivity-app/productivity-app-log-in.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/productivity-app/productivity-app-profile.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/productivity-app/productivity-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
